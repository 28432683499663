<template>
  <div class="blueTop">
    <div class="fluid container">
      <div class="commonWhiteBg low">
        <a-divider class="topTitle">专业律师服务</a-divider>

        <a-row>
          <a-col class="descCol">
            <div class="desc">
              <p> 请选择您要问律师的问题范围： </p>
              <p class="sign">
                <span class="active"> 发现被侵权 </span>
                <span>侵权下架</span>
                <span>企业侵权</span>
                <span>其他</span>
              </p>
              <p> 请联系张律师： </p>
              <a
                class="contactTel"
                href="tel:18080909809"
              > 180 8090 9809 </a>
            </div>
          </a-col>
          <a-col class="imgCol">
            <img src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/law.png">
          </a-col>
        </a-row>

        <div class="qaList">
          <p class="commonTitle">常见问题</p>
          <div
            v-for="(item,index) in qaList"
            :key="index"
          >
            <p class="question"> {{item.q}} </p>
            <p class="answer"> {{item.a}} </p>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LawService',

  data () {
    return {
      qaList: [
        {
          q: "1、咨询律师前需要准备什么？",
          a: "为了高效利用您的咨询时间，请在致电前尽可能把您需要准备的问题梳理清楚。"
        },
        {
          q: "2、有意向委托律师服务，该怎么办？",
          a: "如果是合同、侵权下架等平台已有的服务，您可以联系律师或者联系平台客服。"
        },
        {
          q: "3、律师联系不上怎么办？",
          a: "有时律师可能因为开庭等事项无法及时响应，如果超时未响应，请联系平台客服为您协调更换律师。"
        }
      ]
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>
.blueTop {
  background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/blueBg.svg') repeat-x;
  background-size: auto 224px;
  padding-top: 56px;
}
.low {
  padding: 72px 56px 56px;
  .topTitle {
    color: rgba(0, 18, 22, 0.65);
    font-size: 32px;
  }
  .topTitle::before,
  .topTitle::after{
    top: 0;
  }
  .ant-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    .imgCol {
      width: 50%;
      img {
        max-width: 584px;
        width: 100%;
      }
    }
    .descCol {
      width: 50%;
    }
  }
  .desc {
    padding: 32px;
    box-shadow: 0 0 16px rgba(0, 109, 117, 0.08);
    border-radius: 8px 0 0 8px;
  }
  .sign {
    margin: 24px 0 40px;
    & > span {
      margin-right: 16px;
      border: solid 1px rgba(0, 0, 0, 0.15);
      border-radius: 2px;
      padding: 2px 4px;
      display: inline-block;
      color: rgba(0, 18, 22, 0.65);
    }
    .active {
      background: #007DFF;
      color: #fff;
      border-color: #007DFF;
    }
  }
  .contactTel {
    color: rgba(0, 18, 22, 0.85);
    font-size: 32px;
    font-weight: bold;
  }
  .qaList {
    font-size: 16px;
    .question {
      margin-top: 16px;
      font-weight: bold;
    }
    .answer {
      margin-top: 8px;
      color: rgba(0, 18, 22, 0.65);
    }
  }
}

@media screen and (min-width: 1px) and (max-width: 767px) {
  .blueTop{padding-top: 24px;}
  .low {
    padding: 24px 16px 16px;
    .topTitle {
    color: rgba(0, 18, 22, 0.65);
    font-size: 24px;
  }
    .desc {
    padding:24px 16px;
    }
    .sign {
    margin: 16px 0 24px;
    & > span {
      font-size: 12px;
      margin-right: 8px;
    }
  }
    .contactTel {
      font-size: 24px;
    }
    .ant-row {
      flex-wrap: wrap;
      padding: 16px 0 32px;
      .imgCol {
        order: 1;
        flex: 100%;
      }
      .descCol {
        order: 2;
        flex: 100%;
        text-align: center;
        margin-top: 16px;
      }
    }
  }
}
</style>
